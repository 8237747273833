// App.js
import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from './ThemeContext';
import MenuCima from './paginasIniciais/MenuCima/MenuCima';
import NotFound from './paginasIniciais/NotFound/NotFound';
import AuthPage from './projetos/ToDoList/AuthPage';
import ProtectedRoute from './projetos/ToDoList/ProtectedRoute';
import SoftwareDivisor from './projetos/SoftwareDivisor/TelaInicialDivisor';
import IC from './projetos/IC/IC';

const TelaInicial = lazy(() => import('./paginasIniciais/TelaInicial/TelaInicial'));
const FaçaSeuPedido = lazy(() => import('./paginasIniciais/Cadastro/Cadastro'));
const Projetos = lazy(() => import('./paginasIniciais/Projetos/Projetos'));
const Vitrine = lazy(() => import('./paginasIniciais/Vitrine/Vitrine'));
const ToDoList = lazy(() => import('./projetos/ToDoList/ToDoList'));
const ContagemRegressiva = lazy(() => import('./projetos/ContagemRegressiva/IndexContagemRegressiva'));
const Quizz = lazy(() => import('./projetos/Quiz/QuizApp'));
const SimuladorIdadeAnimal = lazy(() => import('./projetos/Simulador de Idade Animal/IndexSimulador'));
const IndexAvaliacao = lazy(() => import('./projetos/Avaliação do Site/IndexAvaliacao'));
const TelaInicialDevWeb = lazy(() => import('./projetos/WebDevUepg/TelaInicialDevWeb'));
const Trabalho1DevWeb = lazy(() => import('./projetos/WebDevUepg/Trabalho 1/Trabalho1DevWeb'));
const MenuBaixo = lazy(() => import('./paginasIniciais/MenuBaixo/MenuBaixo'));
const Pessoal = lazy(() => import('./paginasIniciais/Pessoal/Pessoal'));
const SoftwareDesktop = lazy(() => import('./projetos/SoftwareDesktop/SoftwareDesktop'));
const GastosResidenciais = lazy(() => import('./projetos/GastosResidenciais/index'));

// Componente que rola para o topo sempre que o pathname muda
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  useEffect(() => {
    fetch('https://app.jeanhenrique.site/registrar-visita', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`
      },
      body: JSON.stringify({
        userAgent: navigator.userAgent,
        referrer: document.referrer
      })
    }).catch(error => {
      // Handle errors silently
    });
  }, []);

  return (
    <Router>
      <ThemeProvider>
        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <MenuCima />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route exact path="/" element={<TelaInicial />} />
            <Route path="/Curriculo" element={<TelaInicial />} />
            <Route path="/FaçaSeuPedido" element={<FaçaSeuPedido />} />
            <Route path="/Vitrine" element={<Vitrine />} />
            <Route path="/Projetos" element={<Projetos />} />
            <Route path="/Projetos/To-Do-List/auth" element={<AuthPage />} />
            <Route path="/Projetos/To-Do-List" element={
              <ProtectedRoute>
                <ToDoList />
              </ProtectedRoute>
            } />
            <Route path="/Projetos/ContagemRegressiva" element={<ContagemRegressiva />} />
            <Route path="/Projetos/Quiz" element={<Quizz />} />
            <Route path="/Projetos/SimuladorIdadeAnimal" element={<SimuladorIdadeAnimal />} />
            <Route path="/Projetos/Avaliação" element={<IndexAvaliacao />} />
            <Route path="/Projetos/WebDev" element={<TelaInicialDevWeb />} />
            <Route path="/Projetos/WebDev/Trabalho1" element={<Trabalho1DevWeb />} />
            <Route path="/Projetos/SoftwareDivisor" element={<SoftwareDivisor />} />
            <Route path="/Projetos/IC" element={<IC />} />
            <Route path="/Pessoal" element={<Pessoal />} />
            <Route path="/Projetos/SoftwareDesktop" element={<SoftwareDesktop />} />
            <Route path="/Projetos/GastosResidenciais" element={<GastosResidenciais />} />
          </Routes>
          <MenuBaixo />
        </Suspense>
      </ThemeProvider>
    </Router>
  );
}

export default App;
